<template>
  <EntityList
    ref="activeList"
    :config="config"
    :columns="columns"
    :custom-query="{ questionnaire: parentEntity.id }"
    :formatted-columns="formattedColumns"
    :form-fields="formFields"
    authority="Questionnaire_Management_Questionnaire"
    embedded
    @entityUpdated="updateEntity"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './sectionsStoreModule'

export default {
  components: {
    EntityList,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    parentEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entity: {},
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'questionnaire-sections',
        endpoint: 'questionnaire-sections',
        route: 'questionnaire-sections',
        title: {
          single: this.$t('Section Add'),
          plural: this.$t('Sections'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'ordering', label: this.$t('ordering'), sortable: true },
        {
          key: 'creationDate',
          label: this.$t('Creation Date'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [{ key: 'creationDate', type: 'datetime' }]
    },
    formFields() {
      return [
        {
          id: 'questionnaire',
          type: 'hidden',
          initVal: { id: this.parentEntity.id },
          hidden: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('description'),
          required: false,
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
          rules: 'integer',
          initVal: 1,
          colSize: 6,
        },
        {
          id: 'visible',
          type: 'checkbox',
          label: this.$t('Visible'),
          initVal: true,
          colSize: 6,
        },
      ]
    },
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    updateEntity(newEntity) {
      this.entity = newEntity
    },
  },
}
</script>
